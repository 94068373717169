import Vue from "vue";
import axios from "axios";
import router from '../router/index';
import authService from '../services/authService';

export default {
  async createHttp() {
    let baseURL_API = await Vue.prototype.$getItem("url-api");
    let http = axios.create({
      baseURL: baseURL_API,
      timeout: 120000,
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: function (status) {
        if (status == 401 || status == 403) {
          router.push({ name: 'NotAuthorized', query: { request: true } });
        }

        return status >= 200 && status < 300; // default
      },
    });

    http.interceptors.request.use(async (request) => {
      Vue.prototype.$startProgress();
      let token = await authService.getToken();
      request.headers.Authorization = 'bearer ' + token;
      return request;
    });

    http.interceptors.response.use(
      (response) => {
        Vue.prototype.$doneProgress();
        return response;
      },
      (error) => {
        Vue.prototype.$doneProgress();
        return error;
      }
    );

    return http;
  },

  async get(url) {
    try {
      let http = await this.createHttp();
      let res = await http.get(url);
      if (!res.data.success) {
        return {
          data: { success: false, data: [{ key: "Error", value: res }] }
        };
      }
      return res.data;


    } catch (err) {
      if (!err || !err.response || !err.response.data) {
        return {
          data: { success: false, data: [{ key: "Error", value: `Request Error ${err.message}` }] }
        };
      }
    }
  },

  async post(url, data) {
    try {
      let http = await this.createHttp();
      const res = await http.post(url, data);
      return res;
    } catch (error) {
      if (!error || !error.response || !error.response.data) {
        return {
          data: { success: false, data: [{ key: "Error", value: `Request Error ${error.message}` }] }
        };
      }
    }
  },

  async put(url, data) {
    try {
      let http = await this.createHttp();
      const res = await http.put(url, data);
      return res;
    } catch (error) {
      if (!error || !error.response || !error.response.data) {
        return {
          data: { success: false, data: [{ key: "Error", value: `Request Error ${error.message}` }] }
        };
      }
    }
  },
  async delete(url, id) {
    try {
      let http = await this.createHttp();
      const res = http.delete(`${url}?id=${id}`);
      return res;
    } catch (error) {
      if (!error || !error.response || !error.response.data) {
        return {
          data: { success: false, data: [{ key: "Error", value: `Request Error ${error.message}` }] }
        };
      }
    }
  },
};


  // async put(url, data, params) {
  //   try {
  //     let http = await this.createHttp();
  //     let res = await http.put(url, data, { params });
  //     return this.getResult(res);

  //   } catch (err) {
  //     if (!err || !err.response || !err.response.data) {
  //       return this.getResult({
  //         data: { success: false, data: [{ key: "Error", value: `Request Error ${err.message}` }] }

  //       });
  //     }
  //     let res = err.response;
  //     if (res.data.success != null) {
  //       return this.getResult(res)
  //     }
  //     return {
  //       success: false, data: [{ key: "Error", value: err.message }]

  //     }
  //   }
  // },




