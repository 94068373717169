export default {
  data() {
    return {};
  },
  methods: {
    notifySuccess(text) {
      return this.$notify({
        title: 'Sucesso!',
        text: text,
        type: 'success',
        max: 1,
        closeOnClick: true,
        duration: 1800,
        ignoreDuplicates: true,
      });
    },
    notifyError(res) {
      let verifyRes = res.data ? res.data.error : res.response ? res.response.data.data[0].value : 'Erro Interno. Contate o Administrator!';
      return this.$notify({
        title: 'Erro',
        text: verifyRes,
        type: 'error',
        max: 1,
        closeOnClick: true,
        duration: 1800,
        ignoreDuplicates: true,
      });
    }
  }
}